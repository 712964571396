*
{
    margin: 0;
    padding: 0;
}



html {
    background-color: black;
    font-size: 1vh;
    color: white;
    /* cursor: none; */
}

body {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    z-index: 1000000;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

@media (orientation:portrait) {
    html {
        font-size: 1.25vw;
    }
}
@media (orientation:landscape) {
    html {
        font-size: 1.25vh;
    }
}

.webgl
{
    position: fixed;
    outline: none;
    box-sizing: border-box;
    /* border: 2px solid blue; */
    cursor: grab;
}

.miniWebGL {
    width: 7.5rem;
    height: 100%;
    position: absolute;
    outline: none;
    box-sizing: border-box;
}

.infoModal {
    height: 100vh;
    width: 30rem;
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    /* border-radius: 2rem 2rem 0rem 0rem; */
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    display: none;
}

.infoModalMain {
    height: calc(100% - 5vh);
    width: 30rem;
    right: 0;
    top: 0;
    position: absolute;
    background-color: transparent;
    /* border-radius: 2rem 2rem 0rem 0rem; */
    /* padding: 2rem; */
    box-sizing: border-box;
    /* display: none; */
    /* border: 2px solid blue; */
    pointer-events: none;
}

.redStrip {
    height: 95%;
    width: 7.5rem;
    position: absolute;
    /* right: 10vh; */
    left: 2rem;
    top: 0;
    background-color: #ff0000;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 200;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    /* box-shadow: 0px 3px 3px black; */
    /* border: 2px solid blue; */
}   

.h1Div {
    font-size: 3.5rem;
    /* font-style: italic; */
    margin-bottom: 4rem;
    font-weight: bolder;
    cursor: default;

    /* text-shadow: 3px 3px 1px black; */
}

.h3Div {
    font-size: 2rem;
    /* font-style: italic; */
    /* margin-left: 3vw; */
    text-align: right;
    /* text-shadow: 3px 3px 1px black; */
    font-weight: bolder;
    margin-bottom: 3.5rem;
    cursor: default;

}

.h3Div2 {
    font-size: 2rem;
    /* font-style: italic; */
    /* margin-left: 3vw; */
    text-align: left;
    /* text-shadow: 3px 3px 1px black; */
    font-weight: bolder;
    cursor: default;

}

.redText {
    font-weight: bold;
    color: red;
}

.contentGrey {
    height: calc(100% - 5vh);
    width: 30rem;
    position: absolute;
    right: 5rem;
    top: 5vh;
    background-color: #00000040;
    /* background-color: #eaeaea; */
    border-radius: 0.5rem 0.5rem 0 0;
    /* border-radius: 2rem 2rem 0rem 0rem; */
    color: white;
    box-sizing: border-box;
    /* display: none; */
    /* border: 2px solid blue;  */
    z-index: 150;
    padding: 2rem;
    pointer-events: none;
    cursor: default;
}

.contentRed {
    height: 100vh;
    width: 30rem;
    position: relative;
    right: calc(5rem + 2rem);
    top: 5vh;
    background-color: transparent;
    /* border-radius: 2rem 2rem 0rem 0rem; */
    color: black;
    /* text-shadow: 3px 3px 1px black; */
    box-sizing: border-box;
    /* display: none; */
    /* border: 2px solid blue;  */
    z-index: 1000;
    padding: 2rem;
    pointer-events: none;
    cursor: default;

}

::selection {
    background-color: transparent;
  }

.closer {
    z-index: 1000;
    position: absolute;
    right: 1vh;
    top: 1vh;
    cursor: pointer;
    color: #ff0000;
    /* font-weight: bold; */
    border: 2px solid #121617;
    background-color: #121617;
    height: 30px;
    width: 30px;
    border-radius: 0.5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
}

.display {
    display: block;
}

@keyframes insertAnimationFromBelow {
    0% {
        opacity: 1;
        transform: translateY(100vh);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes insertAnimationFromAbove {
    0% {
        opacity: 1;
        transform: translateY(-100vh);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes stay {
    0% {
        opacity: 1;
        transform: translateY(200vh);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes canvasStay {
    0% {
        opacity: 1;
        transform: translateY(100vh);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.displayGrey {
    animation: insertAnimationFromBelow 1s forwards;
}

.displayRed {
    animation: insertAnimationFromAbove 1s forwards;
}

.stay {
    animation: stay 1s forwards;
}

.canvasStay {
    animation: canvasStay 1s forwards;
}

.displayGreyx {
    animation: insertAnimationFromBelowx 0.5s forwards;
}

.displayRedx {
    animation: insertAnimationFromAbovex 0.5s forwards;
}

.stayx {
    animation: stayx 0.5s forwards;
}

.canvasStayx {
    animation: canvasStayx 0.5s forwards;
}

.displayx {
    display: none;
}

@keyframes insertAnimationFromBelowx {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(100vh);
    }
}

@keyframes insertAnimationFromAbovex {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(-100vh);
    }
}

@keyframes stayx {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(200vh);
    }
}

@keyframes canvasStayx {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(100vh);
    }
}

.sidebarMain {
    position: fixed;
    height: 100%;
    width: 3rem;
    left: 2rem;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid white; */
    z-index: 10000;
}

.sidebar {
    /* border: 2px solid blue; */
    width: 100%;
    height: 15%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.sidebarLinks {
    width: 100%;
    height: 30%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.sidebarCircle {
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 50%;
    border: 1px solid white;
    background-color: white;
}

.current {
    width: 0.75rem;
    height: 0.75rem;
    background-color: #ff0000;
    border-color: #ff0000;
}

/* loading page */
#loadingPage {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 20000000;
    background-color: #121617;
    /* border: 2px solid white; */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    /* display: none; */
}

#loadingBarContainer {
    height: 5px;
    width: 200px;
    background-color: white;
    overflow: hidden;
}

#loadingBar {
    width: 200px;
    height: 10px;
    background-color: red;
    transform: scaleX(0);
    transform-origin: left;
}

#h1Grey {
    color: red;
    text-shadow: 0.1rem 0.1rem 0.15rem black, 0.1rem 0.1rem 0.15rem black, 0.1rem 0.1rem 0.15rem black;

}

#h1Red {
    color: white;
    text-shadow: 0.1rem 0.1rem 0.15rem black, 0.1rem 0.1rem 0.15rem black, 0.1rem 0.1rem 0.15rem black;
}

#up, #down, #right {
    color: red;
    cursor: pointer;
}

#instructionsBar {
    position: fixed;
    /* border: 2px solid white; */
    width: 37.5rem;
    height: 7.5rem;
    display: flex;
    align-items: center;
    background-color: #121617;
    top: 2rem;
    border-radius: 0 0.5rem 0.5rem 0;
    z-index: 10000;
}

#instructionsTextDiv {
    width: 32.5rem;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.75rem;
    /* border: 2px solid white; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem;
}

#instructionsText {
    text-align: right;
    color: white;
    font-size: 2rem;
}

#rightDiv {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 5rem;
}

.flipped  {
    transform: scaleX(-1);
}

#turnLaptopOn {
    position: fixed;
    text-align: center;
    bottom: 2rem;
    width: 100%;
    height: 2rem;
    /* border: 2px solid white; */
    font-family: 'Montserrat', sans-serif;
    display: none;
    opacity: 0;
}

#turnLaptopOnText {
    font-size: 2rem;
}

#loadingText {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    margin-bottom: 2rem;
}

#loadingDiv {
    text-align: center;
}

#h3Red, #h3Red2 {
    color: white;
    /* text-shadow: 0.15rem 0.15rem 0.15rem black; */
    background-color: #121617;
    box-sizing: border-box;
    padding: 1rem;
    box-shadow: 0 0 0.5rem black, 0 0 0.5rem black;
}

#h3Grey, #h3Grey2 {
    color: white;
    background-color: #121617;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem black, 0 0 0.5rem black;
    z-index: 100000;
}

#h3Grey {
    /* box-shadow: 0 0 0.5rem black, 0 0 0.5rem black; */
}

#h3Grey2 {
    /* box-shadow: 0 0 0.5rem black, 0 0 0.5rem black; */
}

.smallText {
    font-size: 1.75rem;
    /* font-style: normal; */
}

.smallestText {
    font-size: 1.5rem;
    /* font-style: normal; */
}

.mainColor {
    color: red;
}

.subText {
    font-size: 2.5rem;
}

.italic {
    font-style: italic;
}

ul {
    list-style: none; /* Remove default bullets */  
}

ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: red; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: 0rem; /* Also needed for space (tweak if needed) */
}

#up, #down {
    border: 2px solid #121617;
    background-color: #121617;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollEnd {
    /* display: none; */
    position: absolute;
    top: 850vh;
    width: 100vw;
    height: 2vh;
    /* background-color: red; */
}

.scrollMainText {
    position: absolute;
    font-size: 15rem;
    font-family: 'Orbitron', sans-serif;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    color: white;
    box-sizing: border-box;
    /* border: 2px solid blue; */
    text-align: center;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 0 0 3px black;
}

.stayDiv {
    position: absolute;
    top: 100vh;
    width: 100vw;
    height: 80vh;
    box-sizing: border-box;
    /* border: 2px solid red; */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scrollTop {
    position: absolute;
    font-size: 15rem;
    font-family: 'Orbitron', sans-serif;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: red;
    color: transparent;
    box-sizing: border-box;
    /* border: 2px solid blue; */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollBottom {
    position: absolute;
    font-size: 15rem;
    font-family: 'Orbitron', sans-serif;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    color: transparent;
    box-sizing: border-box;
    /* border: 2px solid blue; */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    -ms-overflow-style: none;
    scrollbar-width: none; 
}

body::-webkit-scrollbar { 
    display: none;
}

.scrollBlanks {
    height: 900vh;
    width: 100vw;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    /* border: 2px solid purple; */
    z-index: -10;
    /* pointer-events: none; */
}

.displayBlockMe {
    display: none;
}

.displayNoneMe {
    display: block;
}

.fromTheLeft {
    float: left;
    font-size: 9rem;
    color: white;
    box-sizing: border-box;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    transform: translateX(-200vw);
    z-index: 10;
    text-shadow: 0 0 0.3rem black, -0.25rem 0.25rem 0.1rem white,  -0.3rem 0.3rem 0.1rem black;
    font-family: 'Orbitron', sans-serif;
}

.fromTheRight {
    float: right;
    font-size: 9rem;
    color: white;
    box-sizing: border-box;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    transform: translateX(200vw);
    z-index: 10;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 0 0 0.3rem black, 0.25rem 0.25rem 0.1rem red, 0.3rem 0.3rem 0.1rem black;

}

.middleScrollDiv {
    height: 33%;
    width: 100%;
    /* border: 2px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.topScrollDiv {
    height: 33%;
    width: 100%;
    /* border: 2px solid green; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottomScrollDiv {
    height: 33%;
    width: 100%;
    /* border: 2px solid green; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textCard {
    box-sizing: border-box;
    /* padding: 2rem;
    border: 2px solid black;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 1rem; */
}

.Roboto {
    font-family: 'Roboto', sans-serif;
}

.Lobster {
    font-family: 'Lobster Two', cursive;
}

.Nova {
    font-family: 'Nova Square', cursive;
}

.Merriweather {
    font-family: 'Merriweather', serif;
}

.PressStart {
    font-family: 'Press Start 2P', cursive;
    font-size: 6rem;
}

.Bangers {
    font-family: 'Bangers', cursive;
}

.horizontalScrollDiv {
    position: absolute;
    top: 600vh;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    /* border: 2px solid blue; */
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    z-index: 100;
    cursor: default;
}

.projectsDiv {
    position: fixed;
    width: 20vh;
    height: 100vh;
    background-color: #00000040;
    /* border-radius: 1rem; */
    /* border: 2px solid yellow; */
    box-sizing: border-box;
    overflow: hidden;
    z-index: 5;
}

.projectsTextDiv {
    color: white;
    font-size: 7.5rem;
    /* border: 2px solid orange; */
    box-sizing: border-box;
    height: 20vh;
    width: 400vh;
    transform: rotate(90deg) translateY(-20vh) translateX(-300vh);
    transform-origin: 0% 0%;
    font-family: 'Orbitron', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectsTextDivSubs {
    width: 100vh;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.doubleUp {
    transform: scale(3);
}

.projectsContent {
    width: 400vw;
    height: 100vh;
    /* border: 2px solid violet; */
    color: white;
    font-size: 7.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-wrap: normal;
}

.projectsContentDivSubs {
    width: calc(100vw - 20vh);
    height: 100vh;
    /* border: 2px solid lime; */
    background-color: transparent;
    font-family: 'Orbitron', sans-serif;
    display: flex;
    flex-direction: column;
    margin-left: 20vh;

}

.projectsContentHeader {
    width: 100%;
    height: 20vh;
    text-align: right;
    /* border: 2px solid white; */
    padding: 5rem;
    box-sizing: border-box;
}

.projectsContentText {
    height: 80vh;
    width: 100%;
    /* border: 2px solid white; */
    box-sizing: border-box;
    padding: 5rem;
    padding-top: 10vh;
    font-family: 'BIZ UDPMincho', serif;
}

#mottoText {
    padding-top: 20vh;
    opacity: 0;
}

.Monoton {
    font-family: 'Monoton', cursive;
}

.Pacifico {
    font-family: 'Pacifico', cursive;
}

.pointers {
    cursor: pointer;
}

.gum1 {
    color: #ffc3c3;
}

.gum2 {
    color: #f9ff94;
}

.gum3 {
    color: #92f5ff;
}

.actualProjects {
    text-align: left;
}

.descriptionText {
    font-size: 5rem;
}

.contributionsText {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
}

.stackText {
    font-size: 3.5rem;
}

.linkText {
    font-size: 3.5rem;
    z-index: 1000;
    font-style: none;
    text-decoration: underline;
    cursor: pointer;
    text-decoration-color: white;
}

.PressStartSmaller {
    font-family: 'Press Start 2P', cursive;
    font-size: 3rem;
}

.breakDiv {
    word-wrap: break-word;
}

.Orbitron {
    font-family: 'Orbitron', sans-serif;
}

a::selection {
    background-color: red;
    color: white;
}

.subColor {
    color: white;
}

.emailText {
    font-size: 5.5rem;
    text-decoration-color: white;
}

.fadeInFirst, .fadeInSecond {
    opacity: 0;
}

.otherSmallText {
    font-size: 5rem;
}

.linkText:hover, .emailText:hover {
    text-decoration-color: red;
}

.topGrey {
    height: calc(100% - 5vh);
    width: 30rem;
    position: absolute;
    right: 5rem;
    top: 5vh;
    background-color: transparent;
    /* background-color: #eaeaea; */
    border-radius: 0.5rem 0.5rem 0 0;
    /* border-radius: 2rem 2rem 0rem 0rem; */
    color: white;
    box-sizing: border-box;
    /* display: none; */
    /* border: 2px solid blue;  */
    z-index: 100000;
    padding: 2rem;
}

.shrinkImage {
    width: 100%;
    height: 35rem;
    object-fit: cover;
    scroll-snap-align: start;
}

.imageScroller {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    overflow-x: scroll;
    border-radius: 1rem 1rem 0 0;
    scroll-snap-type: x mandatory;
}

/* width */
.imageScroller::-webkit-scrollbar {
    height: 15px;
    cursor: pointer;
}
  
  /* Track */
.imageScroller::-webkit-scrollbar-track {
    background-color: #121617; 
    /* border: 2px solid white; */
}

/* Handle */
.imageScroller::-webkit-scrollbar-thumb {
    background-color: red; 
    cursor: pointer;
}

/* Handle on hover */
.imageScroller::-webkit-scrollbar-thumb:hover {
    opacity: 0.7;
    cursor: pointer;
}